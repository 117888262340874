.step-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;

  border-radius: 8px;
  background: #06d6a0;

  .prev-btn {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transform: rotate(180deg);
    color: #fff;

    svg {
      transition: all 0.5s ease;
    }
    &:hover svg {
      color: red;
      transform: scale(1.3);
    }
  }
  .center-btn {
    flex: 8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    span {
      color: #000;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .next-btn {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;

    color: #fff;

    svg {
      transition: all 0.5s ease;
    }
    &:hover svg {
      transform: scale(1.3);
      color: yellow;
    }
  }
}

@media (max-width: 768px) {
  .step-btn {
    height: 42px;

    .center-btn {
      span {
        font-size: 14px;
      }
    }
  }
}
