.address-page {
  .step-main {
    // display: flex;
    justify-content: center;
    align-items: flex-start;

    min-height: calc(100vh - 100px - 68px - 314px);

    .step-form {
      width: 100%;
      padding: 41px 30px 52px;
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      background-color: #fff;

      h2.title {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 24px;
      }

      .form-control {
        max-width: 474px;
        margin: 0 auto;

        .box-state {
          display: flex;
          align-items: center;
          margin-top: -5px;
          margin-bottom: 5px;
          padding: 5px 0;
          border-radius: 0 0 3px 3px;

          color: #000;
          // background-color: green;

          .address-icon {
            width: 15px;
            height: 13px;
          }
          p {
            font-size: 10px;
            font-weight: 500;
          }
        }
        // .error {
        //   display: block;
        //   margin: -12px 0 10px;
        // }

        // input {
        //   width: 100%;
        //   height: 50px;
        //   padding: 0px 20px;
        //   border: 1px solid #06D6A0;
        //   outline: #06D6A0;

        //   border-radius: 5px;
        //   margin-bottom: 23px;

        //   color: #000;
        //   font-family: Inter;
        //   font-size: 18px;
        //   font-style: normal;
        //   font-weight: 400;
        //   line-height: normal;

        //   &::placeholder {
        //     color: #a6a7a8;
        //     font-family: Inter;
        //     font-size: 18px;
        //     font-style: normal;
        //     font-weight: 400;
        //     line-height: normal;
        //   }
        // }
      }
    }
  }
}

@media (max-width: 1024px) {
  .address-page {
    .step-main {
      padding: 0 26px;
    }
  }
}

@media (max-width: 768px) {
  .address-page {
    .step-main {
      min-height: calc(100vh - 61px - 210px);
      padding: 0 15px 30px;

      .step-form {
        padding: 20px 15px;
        border-radius: 12px;

        .form-control {
          .box-state {
            // margin-top: -16px;
            margin-bottom: 0;
            padding: 4px 0;

            .address-icon {
              width: 12px;
              height: 12px;
            }
            p {
              font-size: 10px;
            }
          }
        }
        h2.title {
          font-size: 20px;
          margin-bottom: 15px;
        }
        .prev-btn {
          width: 40px;
          height: 35px;

          img {
            max-width: 18px;
          }
        }
      }
    }
  }
}
