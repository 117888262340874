.lgname {
  .step-main {
    // display: flex;
    justify-content: center;
    align-items: flex-start;

    min-height: calc(100vh - 100px - 68px - 314px);

    .step-form {
      width: 100%;
      max-width: 900px;
      padding: 41px 30px 52px;
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      background-color: #fff;

      h2.title {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 41px;
      }

      .form-control {
        max-width: 474px;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .lgname {
    .step-main {
      min-height: calc(100vh - 61px - 210px - 200px);
      padding: 0 15px 10px;

      .step-form {
        padding: 20px 15px;
        border-radius: 12px;
        h2.title {
          font-size: 20px;

          margin-bottom: 15px;
        }

        .prev-btn {
          width: 40px;
          height: 35px;

          img {
            max-width: 18px;
          }
        }
      }
    }
  }
}
