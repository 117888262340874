.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  transform: scale(1.2);

  .circle {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 1px solid #ccc;
    position: absolute;
    animation: pulse 2s ease-in-out infinite;
  }

  .circle1 {
    position: relative;
    // animation: unset;
    // border-color: #48c619;
    /* animation-delay: -0.8s; */
    opacity: 1;
    border: 14px solid #278BA21A !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .circle1.verified h5{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    border-radius: 50%;
  }

  h5 {
    margin: 0;
    color: #FCA905;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;

    width: 100%;
    height: 100%;
  }
  p {
    margin: 0;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 6px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .circle2 {
    transform: scale(1.3);
    animation-delay: -0.6s;
    opacity: 0.6;
  }

  .circle3 {
    transform: scale(1.6);
    animation-delay: -0.4s;
    opacity: 0.4;
  }

  .circle4 {
    transform: scale(1.9);
    animation-delay: -0.2s;
    opacity: 0.2;
  }

  .circle5 {
    transform: scale(2.2);
    animation-delay: 0s;
    opacity: 0.1;
    border-width: 0.5px;
  }

  @keyframes pulse {
    0% {
      border-color: #ccc;
    }
    50% {
      border-color: #278BA2;
    }
    100% {
      border-color: #ccc;
    }
  }

  .upstart {
    position: relative;
    z-index: 5;

    border-radius: 50%;
    background: url(../../assets/images/upstart.png) no-repeat center;
    background-size: 90%;
  }
  .upgrade {
    position: relative;
    z-index: 5;

    border-radius: 50%;
    background: url(../../assets/images/upgrade.png) no-repeat center;
    background-size: 60px;
  }

  &.verified-box .circle {
    border-color: #06D6A0 !important;
  }

  &.verified-box .circle1 {
    position: relative;
    z-index: 5;

    border-radius: 50%;
    background: url(../../assets/images/verified.png) no-repeat center;
    background-size: calc(100% + 62px);
    border-color: transparent !important;
    box-shadow: #83e39a 0px 0px 10px;
  }
}

@media screen and (max-width: 768px) {
  .loader {
    min-height: 184px;
    transform: scale(1);
    .circle2 {
      transform: scale(1.2);
      animation-delay: -0.6s;
      opacity: 0.6;
    }

    .circle3 {
      transform: scale(1.4);
      animation-delay: -0.4s;
      opacity: 0.4;
    }

    .circle4 {
      transform: scale(1.6);
      animation-delay: -0.2s;
      opacity: 0.2;
    }

    .circle5 {
      transform: scale(1.8);
      animation-delay: 0s;
      opacity: 0.1;
      border-width: 0.5px;
    }
  }
}

.MuiLinearProgress-bar {
  background-color: red;
}