.HowItWorksPopup {
  .box-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #3f3f3fdc;

    .container {
      height: 100%;

      max-width: 1532px;
      position: relative;
      flex-direction: column;
      justify-content: center;

      .content-popup {
        position: relative;

        max-width: 1200px;
        padding: 41px 50px;
        height: unset;
        border-radius: 8px;

        overflow-y: unset;

        background-color: #fff;

        button.close {
          background-color: transparent;
          border: none;
          outline: none;

          background: rgba(255, 0, 0, 0.589);
          padding: 7px;
          border-radius: 50%;

          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -45px;
        }

        h2.title {
          color: #000;
          text-align: center;
          font-family: Days One;
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-bottom: 32px;
        }
        h4 {
          margin: 20px 0;
        }
        p.text {
          color: #000;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;

          margin-bottom: 13px;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .HowItWorksPopup {
    .box-popup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 999;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #3f3f3fdc;

      .container {
        height: unset;
        max-width: 100%;
        position: unset;
        display: unset;
        margin-top: -70px;

        .content-popup {
          position: relative;

          max-width: 1200px;
          padding: 0;

          .how-it-work {
            padding: 0;
          }
          button.close {
            left: unset;
            bottom: unset;
            right: 0;
            top: 15px;
          }
        }
      }
    }
  }
}
