@import "../../theme.scss";

.banner {
  position: relative;
  z-index: 1;
  height: calc(100vh - #{$nav-height}px);
  .MuiPaper-root {
    border-radius: 0 0 8px 8px !important;
    overflow: hidden;
  }
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-box {
      max-width: 1030px;
      padding: 64px 50px;
      border-radius: 10px;

      background: url(../../assets/images/bg-new.jpg) no-repeat center center/cover;
      background-repeat: no-repeat;
      background-size: cover;
      // border: 1px solid #48c619;
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);

      .box-field {
        position: relative;

        .error {
          display: block;
          margin: -12px 0 10px;
        }
      }
      .title {
        color: #278ba2;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;

        margin-bottom: 10px;
      }

      // input {
      //   color: #000;
      //   font-family: Inter;
      //   font-size: 18px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;
      // }

      // input[name="email"] {
      //   width: 100%;
      //   height: 50px;

      //   padding: 0 29px;
      //   margin-bottom: 18px;

      //   border: 1px solid #06D6A0;
      //   outline: #06D6A0;
      //   border-radius: 5px;

      //   color: #000;
      //   font-family: Inter;
      //   font-size: 18px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;

      //   &::placeholder {
      //     color: #a6a7a8;
      //     font-family: Inter;
      //     font-size: 18px;
      //     font-style: normal;
      //     font-weight: 400;
      //     line-height: normal;
      //   }
      // }

      .text-field {
        margin-bottom: 18px;
      }
      .tcpa {
        color: #606060;
        text-align: justify;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%; /* 16.8px */

        margin-bottom: 18px;

        a {
          text-decoration-line: underline;
        }
      }
      .btn-submit {
        width: 100%;
        height: 50px;
        margin-bottom: 19px;
      }
      .list-security {
        display: flex;
        justify-content: space-between;

        .item {
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            color: #606060;
            font-family: Inter;
            font-size: 9px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }

    .box-state {
      display: flex;
      align-items: center;
      margin-top: -5px;
      margin-bottom: 5px;
      padding: 5px 0;
      border-radius: 0 0 3px 3px;

      color: #000;
      // background-color: green;

      .address-icon {
        width: 15px;
        height: 13px;
      }
      p {
        font-size: 10px;
        font-weight: 500;
      }
    }
  }
}
.textfield {
  color: #a6a7a8;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {
  .banner {
    height: unset;
    padding: 26px 0 35px;

    h2.title {
      margin-bottom: 15px;
      text-align: center;
    }

    .container {
      display: block;
      padding: 0 20px;

      .banner-box {
        padding: 0;
        width: 100%;

        background: unset;
        .list-security {
          display: flex;
          justify-content: space-between;
          margin-top: 14px;

          .item {
            display: flex;
            gap: 5px;
            align-items: center;
            img {
              max-width: 12px;
            }
            p {
              color: #606060;
              font-family: Inter;
              font-size: 8px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }

        .title {
          text-align: center;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;

          margin-bottom: 11px;
        }
        .tcpa {
          color: #606060;
          text-align: justify;
          font-family: Poppins;
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 14px */

          margin-bottom: 9px;
        }
      }

      .box-state {
        // margin-top: -16px;
        margin-bottom: 0;
        padding: 4px 0;

        .address-icon {
          width: 12px;
          height: 12px;
        }
        p {
          font-size: 10px;
        }
      }
    }
  }
}
