.group-btn {
  max-width: 474px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 17px;
  // padding-top: 10px;

  button,
  .next-btn {
    outline: none;
    border: none;
    cursor: pointer;
  }
  .prev-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 56px;
    height: 50px;

    border-radius: 2px;
    background: #f3f3f3;
  }
  .next-btn {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    background: #f4c811;
    transition: all 0.5s ease;

    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.next-btn {
}
