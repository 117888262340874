.Disclaimer {
  .container-privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;

    min-height: calc(100vh - 100px - 266px);

    .box-privacy {
      max-width: 1200px;
      padding: 13px 62px 22px;

      box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.2);

      h2.title {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 32px;
      }
      .last-update {
        margin-bottom: 20px;

        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: italic;
        font-weight: 300;
        line-height: 130%; /* 18.2px */
      }
      h4 {
        margin: 20px 0;

        color: #000;
        text-align: justify;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 23.4px */
      }

      a {
        text-decoration: underline;
      }
      p {
        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        text-align: justify;

        margin-bottom: 18px;
      }
      li {
        list-style-type: disc;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Disclaimer {
    .container-privacy {
      padding: 0 23px;

      .box-privacy {
        padding: 30px 21px;
        border-radius: 12px;
        h2.title {
          font-size: 22px;
        }
        .last-update {
          font-size: 12px;
        }
        h4 {
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
