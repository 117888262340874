.reject-page {
  padding: 1.5rem 0 4.5rem;

  text-align: center;
  background: #3ba870;

  h1 {
    font-size: 26px;
    line-height: 36px;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .reject-page {
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
