.unsubscribe {
  .container-privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;

    min-height: calc(100vh - 100px - 266px);

    .box-privacy {
      max-width: 547px;
      padding: 13px 62px 22px;

      box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.2);

      h2.title {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 13px;
      }
      p.text {
        color: #000;
        text-align: justify;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 23.4px */

        margin-bottom: 18px;
      }

      .input-field {
        label {
          display: block;
          margin-bottom: 8px;

          color: #000;
          text-align: justify;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        // input {
        //   width: 100%;
        //   height: 50px;
        //   padding: 0px 20px;
        //   margin-bottom: 20px;

        //   border: 1px solid #06D6A0;
        //   border-radius: 4px;

        //   color: #000;
        //   font-family: Inter;
        //   font-size: 18px;
        //   font-style: normal;
        //   font-weight: 400;
        //   line-height: normal;

        //   &::placeholder {
        //     color: #a6a7a8;
        //     font-family: Inter;
        //     font-size: 18px;
        //     font-style: normal;
        //     font-weight: 400;
        //     line-height: normal;
        //   }
        // }
      }
      .select-field {
        label {
          display: block;
          margin-bottom: 8px;

          color: #000;
          text-align: justify;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .unsubscribe {
    .container-privacy {
      padding: 0 23px;

      .box-privacy {
        padding: 25px 16px;

        h2.title {
          font-size: 22px;
        }
        p.text {
          font-size: 16px;
        }

        .input-field {
          label {
            font-size: 14px;
          }

          input {
            height: 38px;
            font-size: 14px;
            padding: 0 15px;

            &::placeholder {
              font-size: 14px;
            }
          }
        }
        .select-field {
          label {
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
}
