.progress-bar {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 11px;
    height: 11px;
    background-color: #06d6a0;
    border-radius: 50%;
  }
}
