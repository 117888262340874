.step-19 {
  .step-main {
    // display: flex;
    justify-content: center;
    align-items: flex-start;

    min-height: calc(100vh - 100px - 68px - 314px);

    .step-form {
      width: 100%;
      padding: 41px 30px 52px;
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      background-color: #fff;

      h2.title {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 20px;
      }

      .form-control {
        max-width: 474px;
        margin: 0 auto;

        .list-btn-radio {
          // display: flex;
          // flex-direction: column;
          // flex-wrap: wrap;
          // gap: 16px;
          // margin-bottom: 26px;

          .btn-radio {
            position: relative;
            flex: 1 1 calc(50% - 92px);
            width: 100%;
            height: 50px;
            margin-bottom: 18px;

            transition: all 0.5s ease;

            border-radius: 10px;
            background: #f5f5f5;

            color: #000;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &.active {
              color: #06d6a0;
              background: #fff;
              border: 1px solid #06d6a0;
            }

            .checked-icon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .step-19 {
    .step-main {
      min-height: calc(100vh - 61px - 210px);
      padding: 0 15px 30px;

      .step-form {
        padding: 20px 15px;
        border-radius: 12px;
        h2.title {
          font-size: 20px;

          margin-bottom: 15px;
        }

        .form-control {
          .list-btn-radio {
            // display: flex;
            // flex-wrap: wrap;
            // gap: 19px;
            // margin-bottom: 20px;

            .btn-radio {
              flex: 1 1 calc(50% - 38px);
              height: 35px;
              font-size: 16px;
            }
          }
        }

        .prev-btn {
          width: 40px;
          height: 35px;

          img {
            max-width: 18px;
          }
        }
      }
    }
  }
}
