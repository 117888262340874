@import "assets/fonts/all.scss";

// FONT
$primary-font: "Inter", sans-serif;
$days-one: "Days One", sans-serif;
// COLORS
$green-gradient: linear-gradient(180deg, #4fb329 0%, #48c619 100%);
$yellow: #fffe0b;

$nav-height: 100;

*,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-padding-top: 200px;

  font-family: $primary-font;
}

html,
body {
  // overflow-x: hidden;
}

a {
  text-decoration: none;
  color: unset;
  transition: all 0.5s ease;
}

button {
  cursor: pointer;
  transition: all 0.5s ease;
  border: none;
}

li {
  list-style-type: none;
}

img {
  max-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  transition: all 0.5s ease;
}

.container {
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

input.text-field {
  width: 100%;
  padding: 15px 30px;
}

input.text-field {
  color: #a6a7a8;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border: 1px solid #55a139;
  border-radius: 4px;
  transition: all 0.5s ease;
}

input.text-field::placeholder {
  color: #a6a7a8;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-submit {
  border-radius: 5px;
  background: #f4c811;
  outline: none;
  border: none;

  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.scrollable-element {
  will-change: transform; /* Hoặc 'scroll-position' tùy thuộc vào trình duyệt */
}
.scrollable-element {
  transform: translateZ(0);
}
.scrollable-element {
  overflow: auto; /* Hoặc overflow: scroll; */
}

.hover-red:hover {
  background-color: red !important;
  color: #fff !important;

  & img {
    filter: brightness(10.5) !important;
  }
}

// button {
//   transition: all 0.5s ease !important;
// }
// button:hover {
//   background-color: #48c7a7 !important;
//   color: #fff !important;

//   & img {
//     filter: brightness(10.5) !important;
//   }
// }

.error {
  font-size: 14px;
  color: red;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (max-width: 768px) {
  .container {
    padding: 0 18px;
  }
  .error {
    font-size: 12px;
  }
}

.css-b62m3t-container {
  input {
    color: #000 !important;

    &::placeholder {
      color: #a6a7a8 !important;
    }
  }

  .css-1jqq78o-placeholder {
    color: #a6a7a8;
    margin: 0;
  }
}
