.badge-icons {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    left: 0;
    right: 0;
    height: 1px;
    background-color: #D5D5D5;
    position: absolute;
    z-index: 1;
  }
  .wrapper {
    display: flex;
    margin: auto;
    column-gap: 16px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: white;
    padding: 0 16px;
    img {
      max-width: unset;
      max-height: 64px;
      z-index: 3;
    }
  }
}