.security-seal {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  height: 30px;
  margin-top: 20px;

  img {
    object-fit: contain;
    max-width: unset;
    width: auto;
  }
}
.sectigo, .ssl {
  height: 30px;
}
.ola {
  height: 34px;
  
}

@media screen and (max-width: 768px) {
  .security-seal {

    .sectigo, .ssl {
      height: 24px;
    }
    .ola {
      height: 30px;
    }
  }
}