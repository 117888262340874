.popup-step {
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  z-index: 99;

  background-color: #191c1ae0;

  .popup-step-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 338px;
    height: 266px;
    border-radius: 8px;
    padding: 23px 30px 0;
    overflow: hidden;

    text-align: center;

    background-color: #fff;

    .popup-step-icon {
      margin-bottom: 13px;
    }
    h3 {
      color: #000;
      text-align: center;
      font-family: Open Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 3px;
    }

    h5 {
      color: #000;
      text-align: center;
      font-family: Open Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .btn-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      display: flex;
      justify-content: space-between;

      button {
        border: 1px solid #0000000d;
        flex: 1;
        padding: 8px 0;

        color: #06d6a0;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
